// 1.
// 引入axios
import axios from 'axios'
// qs 可以把对象 转换为 k=v&k=v 这种格式的字符串
import qs from 'qs'
// 引入存取本地的方法
import local from '@/utils/local.js'
// 引入相应拦截器的message
import { Message } from '@arco-design/web-vue'
// 引入router
import router from '../router/index'
// 2.
// 设置默认的请求服务地址
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.baseURL = '/api/merchant/'

// 设置拦截器
axios.interceptors.request.use(config => {
  const token = local.get('token')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
})

let flag = true
// 响应拦截器
axios.interceptors.response.use(res => {
  if (res.data.code === 2) {
    if (flag) {
      local.del('token')
      // Message.warning('Please log in again.')
      router.push('/login')
      flag = false
    }
  }
  return res.data
}, err => {
  if (err.response.status === 500) {
    router.push('/networkerr')
  } else {
    return err
  }
})

// 设置超时处理
axios.defaults.timeout = 60000

// 3.把请求的方法导出去
export default {
  // post 方式
  post (url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, qs.stringify(params))
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
